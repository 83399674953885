import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import BackgroundImage from 'gatsby-background-image';

import Styles from './index.styles';
import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import HeaderCol from '../components/headerCol/headerCol';
import Swirl from '../images/backgrounds/bg-swirl.svg';
import { ReactComponent as GradientArrow } from '../images/icons/icon-arrow.svg';

const IndexPage = ({ data }) => {
  // Map ACF group content to variable
  const content = data.wpPage.pageHome;

  return (
    <Layout>
      <Seo
        title={data.wpPage.seo.metaTitle}
        description={data.wpPage.seo.metaDesc}
        image={data.wpPage.seo.opengraphImage?.localFile.publicURL}
        ogTitle={data.wpPage.seo.opengraphTitle}
        ogDescription={data.wpPage.seo.opengraphDescription}
        ogImage={data.wpPage.seo.opengraphImage?.localFile.publicURL}
        twitterTitle={data.wpPage.seo.twitterTitle}
        twitterDescription={data.wpPage.seo.twitterDescription}
        twitterImage={data.wpPage.seo.twitterImage?.localFile.publicURL}
      />

      <Styles>
        <BackgroundImage
          Tag="header"
          fluid={content.homeHeader.background.localFile.childImageSharp.fluid}
          fadeIn={false}
          style={{ opacity: `1 !important` }}
        >
          <Container>
            <Row>
              <HeaderCol>
                <div dangerouslySetInnerHTML={{ __html: content.homeHeader.headline}}/>
                <p>{content.homeHeader.subheadline}</p>
                <Link to="/get-started/" className='btn btn-gradient'>
                  {content.homeHeader.button}
                </Link>
              </HeaderCol>
            </Row>
          </Container>

          {/* eslint-disable jsx-a11y/alt-text */}
          <img
            src={Swirl}
            style={{opacity: 1, transform: 'translateY(1px)', height: 'auto', width: '100vw'}}
          />
          {/* eslint-enable jsx-a11y/alt-text */}
        </BackgroundImage>

        <section className='pt-3 pt-md-4'>
          <Container>
            <Row className='justify-content-center text-center'>
              <HeaderCol>
                <h2>{content.homeSubheader.headline}</h2>
                <p>{content.homeSubheader.description}</p>
              </HeaderCol>
            </Row>
          </Container>
        </section>

        <section>
          <Container className='py-4 py-md-6'>
            <Row className='mb-4 align-items-center'>
              <Col md={6} xs={12} className={`mb-3 mb-md-0`}>
                <Img fluid={content.homePitch.offer.image.localFile.childImageSharp.fluid} alt={content.homePitch.offer.image.altText} />
              </Col>
              <Col md={6} xs={12}>
                <h2 className='mb-3'>{content.homePitch.offer.headline}</h2>

                {content.homePitch.offer.offerings.map((offer, index) => (
                  <Row className='align-items-center mb-2' key={index}>
                    <Col xs={2}>
                      <GradientArrow />
                    </Col>
                    <Col xs={10} key={`offer-${index}`}>
                      {offer.offeringText}
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>

            <Row className='mb-5 align-items-center'>
              <Col xs={{ span: 12 , order: 'first' }} md={{ span:6 , order: 'last' }} className={`mb-3 mb-md-0`}>
                <Img fluid={content.homePitch.journey.image.localFile.childImageSharp.fluid} alt={content.homePitch.journey.image.altText} />
              </Col>
              <Col xs={{ span: 12 , order: 'last' }} md={{ span:6 , order: 'first' }}>
                <h2 className='mb-3'>{content.homePitch.journey.headline}</h2>
                <p className='mb-3 mb-md-5'>{content.homePitch.journey.description}</p>
                <Link to="/get-started/" className='btn btn-gradient'>
                  {content.homePitch.journey.button}
                </Link>
              </Col>
            </Row>

            <Row className='mb-5 align-items-center'>
              <Col md={6} xs={12} className={`mb-3 mb-md-0`}>
                <Img fluid={content.homePitch.library.image.localFile.childImageSharp.fluid} alt={content.homePitch.library.image.altText} />
              </Col>
              <Col md={6} xs={12} >
                <h2 className='mb-3'>{content.homePitch.library.headline}</h2>
                <p className='mb-3 mb-md-5'>{content.homePitch.library.description}</p>
                <Link to="/browse/" className='btn btn-gradient'>
                  {content.homePitch.library.button}
                </Link>
              </Col>
            </Row>
          </Container>

          <div className="banner-tertiary py-5">
            <Container>
              <Row className='justify-content-center text-center'>
                <HeaderCol>
                  <h2>{content.homePrinciples.headline}</h2>
                  <h3 className='mb-0'>{content.homePrinciples.subheadline}</h3>
                </HeaderCol>
              </Row>
            </Container>
          </div>

          <Container className='pt-4 pt-md-6 pb-4'>
            <Row className='justify-content-center'>
              {content.homePrinciples.leadership.map((leader, index) => (
                <Col md={3} xs={6} className='text-center mb-4' key={index}>
                  <h4>{leader.name}</h4>
                  <Img fluid={leader.headshot.localFile.childImageSharp.fluid} alt={leader.name} className='rounded-circle my-3 mx-4' />
                  <p>{leader.bio}</p>
                </Col>
              ))}
            </Row>
          </Container>

          <div className="banner-primary py-5" style={{ borderBottomRightRadius: '80.9rem', borderTopRightRadius: '80.9rem'}}>
            <Container>
              <Row className="align-items-center">
                <Col md={8}>
                  <h4>{content.homeGetStarted.headline}</h4>
                </Col>

                <Col md={4} className='text-md-center text-left'>
                  <Link to="/get-started/" className='btn btn-gradient d-inline-flex'>
                    {content.homeGetStarted.button}
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>

          <Container className='py-4 py-md-6' id="about-us">
            <Row className='mb-5'>
              <Col>
                <h2>{content.homeAbout.headline}</h2>
                <p>{content.homeAbout.description}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h2>{content.homeFaqs.headline}</h2>
                <Accordion>
                {content.homeFaqs.faqs.map((question, index) => (
                  <div className="question py-4" key={`question-${index}`}>
                    <Accordion.Toggle
                      as={'div'}
                      eventKey={`question-${index}`}
                      style={{position: 'relative'}}
                      className='pb-3'
                    >
                      {question.question}
                      <span className='display-4' style={{fontWeight: 'normal', display: 'block', position: 'absolute', top: '0', right: '0'}}>+</span>
                      {/*<span className='display-4' style={{fontWeight: 'normal', display: 'block', position: 'absolute', top: '0', right: '0'}}>-</span>*/}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`question-${index}`}>
                      <p>{question.answer}</p>
                    </Accordion.Collapse>
                  </div>
                ))}
              </Accordion>
              </Col>
            </Row>
          </Container>

          <div className="banner-secondary py-5">
            <Container>
              <Row className='justify-content-center text-center'>
                <Col md={6} className='d-flex flex-column mb-4 mb-md-0'>
                  <h2>{content.homeBanner.learnMoreHeadline}</h2>
                  <Link to="/learn-more/" className='btn btn-gradient mt-auto mx-auto'>
                    {content.homeBanner.learnMoreButton}
                  </Link>
                </Col>

                <Col md={6} className='d-flex flex-column'>
                  <h2>{content.homeBanner.connectHeadline}</h2>
                  <Link to="/connect/" className='btn btn-gradient mt-auto mx-auto'>
                    {content.homeBanner.connectButton}
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </Styles>
    </Layout>
  );
};

export const query = graphql`
  query {
      wpPage(databaseId: {eq: 2}) {
          seo {
              metaDesc
              metaTitle
              twitterDescription
              twitterTitle
              twitterImage {
                  localFile {
                      publicURL
                  }
              }
              opengraphTitle
              opengraphDescription
              opengraphImage {
                  localFile {
                      publicURL
                  }
              }
          }
          pageHome {
              homeHeader {
                  background {
                      localFile {
                          ...imageFluidExtraLarge
                      }
                  }
                  headline
                  subheadline
                  button
              }
              homeSubheader {
                  headline
                  description
              }
              homePitch {
                  offer {
                      headline
                      offerings {
                          offeringText
                      }
                      image {
                          localFile {
                              ...imageFluidMedium
                          }
                          altText
                      }
                  }
                  journey {
                      headline
                      description
                      button
                      image {
                          localFile {
                              ...imageFluidMedium
                          }
                          altText
                      }
                  }
                  library {
                      headline
                      description
                      button
                      image {
                          localFile {
                              ...imageFluidMedium
                          }
                          altText
                      }
                  }
              }
              homePrinciples {
                  headline
                  subheadline
                  leadership {
                      name
                      bio
                      headshot {
                          localFile {
                              ...imageFluidSmall
                          }
                          altText
                      }
                  }
              }
              homeGetStarted {
                  headline
                  button
              }
              homeAbout {
                  headline
                  description
              }
              homeFaqs {
                  headline
                  faqs {
                      question
                      answer
                  }
              }
              homeBanner {
                  learnMoreHeadline
                  learnMoreButton
                  connectHeadline
                  connectButton
              }
          }
      }
  }
`;

export default IndexPage;
