import styled from '@emotion/styled';
import colors from "../styles/modules/colors.module.scss";

const Styles = styled.main({
  '.question': {
    borderBottom: `1px solid ${colors.primary}`,

    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default Styles;
